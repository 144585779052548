(function ($) {
  'use strict';
  // window.svg4everybody({
  //   fallback: function (src, svg, use) {
  //     // src: current xlink:href String
  //     // svg: current SVG Element
  //     // use: current USE Element

  //     var fallbackSRC = src.replace(/(.*\/).*#(.*)/, '$1fallback-png/$2.png');

  //     return fallbackSRC;
  //   }
  // });

  // old ie
  window.Promise.prototype.notThen = window.Promise.prototype['catch'];

  var name = window.document.documentElement.className;
  name = name.replace('no-js', 'js');
  window.document.documentElement.className += name ? ' js' : 'js';
  window.document.documentElement.className += ' not-ready';

  window.scala.documentReady.then(function () {
    var className = window.document.documentElement.className;
    window.document.documentElement.className = className.replace('not-ready', 'ready');
  })

  var setLoaded = function () {
    var oldClass = window.document.documentElement.className;
    window.document.documentElement.className = oldClass.replace('loading', 'loaded');
  };

  // old syntax on purpose
  window.onload = setLoaded;

  window.addEventListener('load', setLoaded);
})();
