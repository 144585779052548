
(function () {
  'use strict';

  window.scala = window.scala || {};

  window.scala.utils = {
    //        shamelessly copy-pasted from underscoreJS
    // Returns a function, that, when invoked, will only be triggered at most once
    // during a given window of time. Normally, the throttled function will run
    // as much as it can, without ever going more than once per `wait` duration;
    // but if you'd like to disable the execution on the leading edge, pass
    // `{leading: false}`. To disable execution on the trailing edge, ditto.
    throttle: function (func, wait, options) {
      var context;
      var args;
      var result;
      var timeout = null;
      var previous = 0;
      if (!options) { options = {}; }
      var later = function () {
        previous = options.leading === false ? 0 : window.performance.now();
        timeout = null;
        result = func.apply(context, args);
        if (!timeout) { context = args = null; }
      };
      return function () {
        var now = window.performance.now();
        if (!previous && options.leading === false) { previous = now; }
        var remaining = wait - (now - previous);
        context = this;
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
          if (timeout) {
            clearTimeout(timeout);
            timeout = null;
          }
          previous = now;
          result = func.apply(context, args);
          if (!timeout) { context = args = null; }
        }
        else if (!timeout && options.trailing !== false) {
          timeout = setTimeout(later, remaining);
        }
        return result;
      };
    },

    cumulativeOffset: function (element) {
      var top = 0;
      var left = 0;

      do {
        top += element.offsetTop || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent;
      } while (element);

      return {
        top: top,
        left: left
      };
    },

    queryAll: function (selectorString, context) {
      var element = context || document;
      return Array.prototype.slice.call(element.querySelectorAll(selectorString));
    },

    //fix safari bug where responsive images added via javascript won't load
    fixResponsiveImages: function (holders) {
      var holders = holders || [document];

      for (var i = 0, length = holders.length; i < length; i += 1)
      {
        var currHolder = holders[i];
        var images = currHolder.querySelectorAll('img[srcset]');
        for (var i = 0, length = images.length; i < length; i += 1)
        {
          var curr = images[i];
          var newIMG = curr.cloneNode(true);
          curr.parentElement.insertBefore(newIMG, curr);
          curr.parentElement.removeChild(curr);
        }
      }
    },

    checkPeriodically: function (toCheck) {
      return new Promise(function (resolve, reject) {
        var stateCheck = setInterval(function () {
          if (toCheck()) {
            clearInterval(stateCheck);
            resolve();
          }
        }, 100);
      });
    }
  };
}());
